import styled from 'styled-components';
import CloseIcon from '../assets/close.svg';

interface HamburgerProps {
  open: boolean;
}

export const DrawerWrapper = styled.div<HamburgerProps>`
  height: 350px;
  z-index: 9999;
  display: none;
  justify-content: center;

  background: var(--green-drawer);

  @media (max-width:820px) {
    top: 0;
    right: 0%;
    width: 100%;
    position: fixed;
    height: fit-content;
    flex-direction: column;
    display: ${props => props.open ? 'flex' : 'none'};
  }
`;

export const CloseWrapper = styled.div`
  height: 40px;
  display:flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Close = styled(CloseIcon)`
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin: 1rem 1rem 1rem 1rem;
`;

