import styled from 'styled-components';
import Arrow from '../../../../../../public/IconeSair.svg'
export const ContentWrapper = styled.div`
  display: flex;

  @media (max-width: 820px) {
    display: none;
  }

  .seta {
    display: flex;
    position: relative;
    margin: 0 0 0 .2rem;
    scale: 35%;
    cursor: pointer;
    justify-content: center;
    transform: rotate(360deg);

    &:hover {
      scale: 40%;
    }
  }

  .seta span {
    width: 50px;
    height: 5px;
    background-color: #fff;
    position: relative;


  }

  .seta span:before,
  .seta span:after {
    margin-left: 22px;
    content: "";
    width: 35px;
    height: 100%;
    background-color: #fff;
    position: relative;
    display: flex;
  }

  .seta span:before {
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    bottom: 11px;
  }

  .seta span:after {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    bottom: -6px;
  }
`;

export const VerticalDivider = styled.div`
  width: 4px;
  height: 31px;
  margin-right: 10px;
  background-color: #21725E;
`;


export const ExitArrow = styled(Arrow)`
  width:30px ;
  height: 30px;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;
