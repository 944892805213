import {
  Close,
  Title,
  Header,
  Overlay,
  FormWrapper,
  FieldWrapper,
  ContentWrapper,
  SendMessageWrapper
} from './styles';
import React from 'react';
import { useAuth } from '@/hooks';
import { Form } from '@unform/web';
import { ForgotPasswordService } from '@/service';
import { emailValidator } from '@/utils/emailValidator';
import { TextField } from '@/global/components/Inputs/TextField';
import { LoadingButton } from '@/global/components/LoadingButton';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ForgotPassword: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { setOPenForgotPassword } = useAuth();
  const [email, setEmail] = React.useState('');
  const { forgotPass } = new ForgotPasswordService();
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [sendMessage, setSendMessage] = React.useState(false);

  const handleSubmit = ({ email }: { email: string }) => {
    if (!email) return;

    setLoadingButton(true);
    forgotPass({ email }).then(({ data }) => {
      const { token } = data;
      if (token) {
        const [letter, domain] = email.split('@');
        const splitLetter = letter.split('');
        const newArr = [] as any[];
        splitLetter.forEach((item, index) => {
          if (index <= 2) {
            newArr.push(item);
          } else {
            newArr.push('#');
          }
        });

        setEmail(newArr.join('') + '@' + domain);
        setSendMessage(!sendMessage);

        setTimeout(() => {
          setOPenForgotPassword(false);
          setSendMessage(false);
          setLoadingButton(false);
        }, 3000);
      }
    });
  };

  return (
    <>
      <ContentWrapper open={isOpen}>
        <Header>
          <Title>
            Esqueceu a senha?
          </Title>
          <Close onClick={onClose}/>
        </Header>
        {!sendMessage && (
          <FormWrapper>
            <Form ref={null} onSubmit={handleSubmit}>
              <span>
                Informe seu e-mail e enviaremos instruções
                para você criar uma nova senha.
              </span>
              <FieldWrapper>
                <TextField name='email' placeholder='E-mail' />
              </FieldWrapper>
              <button disabled={loadingButton}>
                {!loadingButton ? ('Enviar') : (<LoadingButton />)}
              </button>
            </Form>
          </FormWrapper>
        )}
      {sendMessage && (
          <SendMessageWrapper>
            <span>E-mail enviado.</span>
            <p>
              Enviamos um e-mail com instruções para
              a criação de uma nova senha para:
            </p>
            <span>{email}</span>
          </SendMessageWrapper>
      )}
      </ContentWrapper>
      <Overlay open={isOpen} onClick={onClose} />
    </>
  );
};
