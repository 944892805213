import { useAuth } from '@/hooks';
import React, { Fragment } from 'react';
import { RegisterForm } from '../Register';
import { useMediaQuery } from '@mui/material';
import { ForgotPassword } from './ForgotPass';
import { canvasSize } from '@/utils/canvasMatches';
import { TextForm } from '../../../atoms/Header/Text';
import { LoginForm } from '@/components/home/atoms/Header/Login';
import { ContentWrapper, ExitArrow, VerticalDivider } from './styles';

export const FormsHeader: React.FC = () => {
  const {
    openLogin,
    tokenDecode,
    setOpenLogin,
    openRegister,
    handleLogout,
    authenticated,
    setOpenRegister,
    setAuthenticated,
    openForgotPassword,
    setOPenForgotPassword,
  } = useAuth();

  const viewPort = useMediaQuery(canvasSize);
  React.useEffect(() => {
    if (viewPort) setOpenLogin(false);
  }, [viewPort]);

  React.useEffect(() => {
    if (tokenDecode !== null) {
      setAuthenticated(true);
    };
  }, [authenticated, tokenDecode]);

  const username = tokenDecode?.name?.split(' ')[0] as string;

  return (
    <Fragment >
      <ContentWrapper>
        {!authenticated ? (
          <>
            <TextForm
              title='Login'
              onClick={() => setOpenLogin(!openLogin)}
            />
            <VerticalDivider />
            <TextForm
              length={105}
              title='Cadastre-se'
              onClick={() => setOpenRegister(!openRegister)}
            />
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              color: '#FFF',
              justifyContent: 'center',
              alignItems: 'center',
              font: 'normal normal 300 16px/26px Poppins'
            }}
          >
            <p>Olá, <b>{username}</b></p>
            <ExitArrow onClick={handleLogout} />
          </div>
        )}
      </ContentWrapper>
      <LoginForm isOpen={openLogin} />
      <RegisterForm
        open={openRegister}
        onClose={() => setOpenRegister(false)}
      />
      <ForgotPassword
        isOpen={openForgotPassword}
        onClose={() => setOPenForgotPassword(!openForgotPassword)}
      />
    </Fragment>
  );
};
