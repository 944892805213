import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/material';
import { isSafari } from 'react-device-detect';
import { ContainerList, Divider } from './styles';
import { canvasSize } from '@/utils/canvasMatches';
import { ChallengeItem } from '@/global/components/challengeItem';

type ItemProps = 'Início' | 'Ranking' | 'Visão por indicador' | 'Análise' | 'O DGE';
interface IProps {
  handleClickLogin: () => void;
  handleClickRegisterForm: () => void;
}

const ListItems = ['Início', 'Ranking', 'Desafios', 'Visão por indicador', 'Análise', 'O DGE'];

export const List: React.FC<IProps> = ({ handleClickLogin, handleClickRegisterForm }) => {
  const router = useRouter();
  const isMobile = useMediaQuery(canvasSize);
  const pathAtual = router.pathname;

  const handleGenerateNavigationString = (clickedItem: string) => {

    if (clickedItem !== pathAtual) {

      switch (clickedItem) {
        case 'Início':
          return '/';
        case 'Ranking':
          return '/ranking';
        case 'Visão por indicador':
          return '/indicadores';
        case 'Análise':
          return '/analise';
        case 'O DGE':
          return '/dge';
        default:
          break;
      }
    }
  };

  const handleRouterNavigation = (e: any) => {
    const clickedItem = e.currentTarget.outerText as ItemProps;

    if (clickedItem !== pathAtual) {

    switch (clickedItem) {
      case 'Início':
        router.push('/');
        break;
      case 'Ranking':
        router.push('/ranking');
        break;
      case 'Visão por indicador':
        router.push('/indicadores');
        break;
      case 'Análise':
        router.push('/analise');
        break;
      case 'O DGE':
        router.push('/dge');
        break;
      default:
        break;
    }
  }
  };

  return (
    <ContainerList>
      {
        isSafari && isMobile ? (
          <>
            {
              ListItems?.map((item, index) => {
                if (item !== 'Desafios') {
                  return (
                    <li key={(Math.random() + 100)}>
                      <Link href={`${(handleGenerateNavigationString(item))}`} >{item}</Link>
                    </li>
                  );
                } else {
                  return <ChallengeItem key={index} label={item} isMobile={true} />;
                }
              })
            }
          </>
        ) : (
          <>
            {
              ListItems?.map((item, index) => {
                if (item !== 'Desafios') {
                  return (
                    <li key={(Math.random() + 100)} onClick={handleRouterNavigation}>
                      <p>{item}</p>
                    </li>
                  );
                } else {
                  return <ChallengeItem key={index} label={item} isMobile={true} />;
                }
              })
            }
          </>
        )
      }
      <Divider />
      <button onClick={handleClickLogin} onTouchStart={handleClickLogin}>
        <p>Login</p>
      </button>
      <button onClick={handleClickRegisterForm} onTouchStart={handleClickRegisterForm}>
        <p>Cadastre-se</p>
      </button>
    </ContainerList>
  );
};
