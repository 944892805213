export const estados = [
  { id: 1, nome: 'Rondônia', sigla: 'RO', pdf: "desafios/RO.pdf" },
  { id: 2, nome: 'Acre', sigla: 'AC',  pdf: "desafios/AC.pdf" },
  { id: 3, nome: 'Amazonas', sigla: 'AM',  pdf: "desafios/AM.pdf" },
  { id: 4, nome: 'Roraima', sigla: 'RR',  pdf: "desafios/RR.pdf" },
  { id: 5, nome: 'Pará', sigla: 'PA',  pdf: "desafios/PA.pdf" },
  { id: 6, nome: 'Amapá', sigla: 'AP',  pdf: "desafios/AP.pdf" },
  { id: 7, nome: 'Tocantins', sigla: 'TO',  pdf: "desafios/TO.pdf" },
  { id: 8, nome: 'Maranhão', sigla: 'MA',  pdf: "desafios/MA.pdf" },
  { id: 9, nome: 'Piauí', sigla: 'PI',  pdf: "desafios/PI.pdf" },
  { id: 10, nome: 'Ceará', sigla: 'CE',  pdf: "desafios/CE.pdf" },
  { id: 11, nome: 'Rio Grande do Norte', sigla: 'RN',  pdf: "desafios/RN.pdf" },
  { id: 12, nome: 'Paraíba', sigla: 'PB',  pdf: "desafios/PB.pdf" },
  { id: 13, nome: 'Pernambuco', sigla: 'PE',  pdf: "desafios/PE.pdf" },
  { id: 14, nome: 'Alagoas', sigla: 'AL',  pdf: "desafios/AL.pdf" },
  { id: 15, nome: 'Sergipe', sigla: 'SE',  pdf: "desafios/SE.pdf" },
  { id: 16, nome: 'Bahia', sigla: 'BA',  pdf: "desafios/BA.pdf" },
  { id: 17, nome: 'Minas Gerais', sigla: 'MG',  pdf: "desafios/MG.pdf" },
  { id: 18, nome: 'Espírito Santo', sigla: 'ES',  pdf: "desafios/ES.pdf" },
  { id: 19, nome: 'Rio de Janeiro', sigla: 'RJ',  pdf: "desafios/RJ.pdf" },
  { id: 20, nome: 'São Paulo', sigla: 'SP',  pdf: "desafios/SP.pdf" },
  { id: 21, nome: 'Paraná', sigla: 'PR',  pdf: "desafios/PR.pdf" },
  { id: 22, nome: 'Santa Catarina', sigla: 'SC',  pdf: "desafios/SC.pdf" },
  { id: 23, nome: 'Rio Grande do Sul', sigla: 'RS',  pdf: "desafios/RS.pdf" },
  { id: 24, nome: 'Mato Grosso do Sul', sigla: 'MS',  pdf: "desafios/MS.pdf" },
  { id: 25, nome: 'Mato Grosso', sigla: 'MT',  pdf: "desafios/MT.pdf" },
  { id: 26, nome: 'Goiás', sigla: 'GO',  pdf: "desafios/GO.pdf" },
  { id: 27, nome: 'Distrito Federal', sigla: 'DF',  pdf: "desafios/DF.pdf" },
].sort((a, b) => {
  if (a.sigla.toLowerCase() < b.sigla.toLowerCase()) {
    return -1;
  }
  if (a.sigla.toLowerCase() > b.sigla.toLowerCase()) {
    return 1;
  }
  return 0;
});
