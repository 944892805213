import React from 'react';
import { List } from './List';
import { useAuth } from '@/hooks';
import { DrawerLogin } from './Login';
import { useMediaQuery } from '@mui/material';
import { canvasSize } from '@/utils/canvasMatches';
import { DrawerWrapper, CloseWrapper ,Close } from './styles';
import { RegisterForm } from '@/components/ranking/molecules/Header/Register';

export interface DrawerImperativeProps {
  toggle: () => void;
}

export const DrawerFowardRef = React.forwardRef<DrawerImperativeProps, any>((_, ref) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [isOpenRegisterMobile, setIsOpenRegisterMobile] = React.useState<boolean>(false);

  const viewPort = useMediaQuery(canvasSize);
  const { openDrawerLogin, setOpenDrawerLogin } = useAuth();

  React.useEffect(() => {
    if (!viewPort) {
      setIsOpenRegisterMobile(false);
    }
  }, [viewPort]);

  const handleToggle = () => {
    setOpen(!open);
  };

  React.useImperativeHandle(ref, () => ({
    toggle: () => setOpen(!open)
  }), [open]);

  const handleClickLogin = () => {
    setOpen(false);
    setOpenDrawerLogin(true);
  };


  const handleClickRegisterForm = () => {
    setOpen(false);
    setIsOpenRegisterMobile(true);
  };

  return (
    <>
      <DrawerWrapper open={open}>
        <CloseWrapper onClick={handleToggle}>
          <Close />
        </CloseWrapper>
        <List handleClickLogin={handleClickLogin} handleClickRegisterForm={handleClickRegisterForm} />
      </DrawerWrapper>
      <DrawerLogin isOpen={openDrawerLogin} onClose={() => setOpenDrawerLogin(false)} />
      <RegisterForm open={isOpenRegisterMobile} onClose={() => setIsOpenRegisterMobile(false)}  />
    </>
  );
});
