import styled from 'styled-components';
import Exclamation from './assets/exclamation.svg';

interface Iprops {
  width: string;
}

export const FormWrapper = styled.div<Iprops>`
  height: 40%;
  margin: 1.5rem 1rem 1rem 0;
  width: ${props => props.width}%;

  div:nth-child(2) {
    margin-top: .5rem;
  }
`;

export const ErrorIcon = styled(Exclamation)``;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    color: #E52927;
    margin: 0 0 0 .3rem;
    font: italic normal normal 12px/19px Poppins;
  }
`;

export const InputWrapper = styled.div`
  background: #E7E7E7;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 3px 4px 6px 0px rgba(0,0,0,0.61);
  -webkit-box-shadow: 3px 4px 6px 0px rgba(0,0,0,0.61);

  input[type=text] {
    height: 90%;
    width: 84%;
    border: none;

    &:focus {
      outline: none;
    }
  }
`;

export const WrapperActions = styled.div`
  display: flex;
  /* margin-top: .5rem; */
  align-items: center;
  justify-content: space-between;

  p {
    margin: .5rem 0 0 0;
    font: italic normal normal 12px/19px Poppins;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (max-width:390px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Button = styled.button`
  width: 100%
`;

export const CreateWrapper = styled.div`
  display: flex;
  font: normal normal normal 12px/19px Poppins;
  margin-top: 0.4rem;
  p {
    margin: 0 .5rem 0 0;
  }

  p:nth-child(2) {
    font-weight: bold;
    color: black;
    text-decoration: underline;

    &:hover {
      text-decoration-color: var(--green);
      color: var(--green);
      cursor: pointer;
    }
  }

  @media (max-width:390px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;

interface IProps {
  isOpen: boolean;
}

export const Overlay = styled.div<IProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999999;
  background: transparent;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;
