import React from 'react';
import { Container } from './styles';
import { DrawerFowardRef, DrawerImperativeProps } from '../../../atoms/Header/Drawer';

export const Hamburguer: React.FC = () => {
  const drawerRef = React.useRef<DrawerImperativeProps>(null);
  return (
    <>
      <Container onClick={() => drawerRef.current?.toggle()}>
        <div></div>
        <div></div>
        <div></div>
      </Container>
      <DrawerFowardRef ref={drawerRef} />
    </>
  );
};
