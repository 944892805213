import styled from 'styled-components';

interface IProps {
  error: boolean;
}

export const WrapperSelect = styled.div<IProps>`
  flex-grow: 1;
  display: flex;
  justify-content: center;

  select {
    border: none;
    display: flex;
    height: 2rem;
    background: #fff;
    border-radius: 2px;
    margin: 0 1rem 0 0;
    padding:  0 0 0 .5rem;
    border: 1px solid #C2C1C1BF;
    border: ${props => props.error && `2px solid #E52927`};
    color: black !important;

    &::placeholder {
      color: #000000;
    }
  }

  ::placeholder {
    text-align: left;
  }
`;
