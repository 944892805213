import React from 'react';
import { Container } from './styles';

export const LoadingButton: React.FC = () => {
  return (
    <Container>
        <div className="circleCell" />
        <div className="circleCell" />
        <div className="circleCell" />
    </Container>
  );
};
