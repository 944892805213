import styled, { keyframes } from 'styled-components';

const circleAnimation = keyframes`
  0% { opacity: 0; transform: scale(1) }
  50% { opacity: 1; background: #1A8271; transform: scale(1.3) }
  100% { opacity: 0; transform: scale(0.4) }
`;


export const Container = styled.div`
  position: relative;
  width: 10px;
  height: 20px;
  display: grid;
  padding: auto 60px;
  align-items: center;
  transform: scale(.3);
  justify-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 60px);

  .circleCell {
    width: 28px;
    height: 28px;
    background: white;
    border-radius: 50%;
  }

  .circleCell:nth-child(1) {
    animation: ${circleAnimation} 2s cubic-bezier(0.38, 0.08, 0.25, 1.08) infinite -0.6s;
  }

  .circleCell:nth-child(2) {
    animation: ${circleAnimation} 2s cubic-bezier(0.38, 0.08, 0.25, 1.08) infinite -0.3s;
  }

  .circleCell:nth-child(3) {
    animation: ${circleAnimation} 2s cubic-bezier(0.38, 0.08, 0.25, 1.08) infinite;
  }
`;
