import React from 'react';
import { Tooltip } from '@mui/material';
import { useField } from '@unform/core';
import { WrapperSelect } from './styles';

interface IProps {
  name: string;
  width?: string;
  required?: boolean;
  defaultValue: any;
  defaultItem: string;
  placeholder?: string;
  children: React.ReactNode;
  onChange?: (e: any) => void;
  options: Record<string, unknown>[];
}

export function SelectField({ name, children, defaultItem, onChange, required=false, placeholder, width }: IProps) {
  const selectRef = React.useRef(null);
  const isWidth = width || '100%';
  const { fieldName, defaultValue, registerField, error } = useField(name);

  React.useEffect(() => {
    registerField({
      ref: selectRef,
      name: fieldName,
      getValue: ref => ref.current?.value,
      setValue: (ref, newValue) => {
        ref.current.value = newValue;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <WrapperSelect error={!!error}>
      <select
        id={fieldName}
        ref={selectRef}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        style={{ width: isWidth }}
        defaultValue={defaultValue}
      >
        <option value='0'>{defaultItem}</option>
        {children}
      </select>
    </WrapperSelect>
  );
}
