import {
  User,
  PadLock,
  Overlay,
  Container,
  ClipPathWrapper,
} from './styles';
import React from 'react';
import { useAuth } from '@/hooks';
import { LoginGuide } from '@/global/guides/Login';
import { IconInput } from '@/global/components/Inputs/IconTextField';

interface IProps {
  isOpen: boolean;
}

export const LoginForm: React.FC<IProps> = ({ isOpen }) => {
  const { formRef, handleLogin, setOpenLogin, openLogin } = useAuth();

  return (
    <>
      <Container isOpen={isOpen}>
        <ClipPathWrapper isOpen={isOpen}>
          <LoginGuide onSubmit={handleLogin}>
          <IconInput
              name='email'
              icon={<User />}
              placeholder='E-mail'
              onChange={() => formRef.current?.setFieldError('email', '')}
            />
            <IconInput
              name='password'
              type='password'
              icon={ <PadLock />}
              placeholder='Senha'
              onChange={() => formRef.current?.setFieldError('password', '')}
            />
          </LoginGuide>
        </ClipPathWrapper>
      </Container>
      <Overlay isOpen={isOpen} onClick={() => setOpenLogin(!openLogin)} />
    </>
  );
};
