import ArrowSvg from './svg/ar.svg';
import styled, { css } from 'styled-components';

interface SelectMenuProps {
  isOpen: boolean;
}

interface SelectButtonProps {
  isMobile: boolean;
}

export const InputContainer = styled.div<SelectButtonProps>`
  width: 100%;
  margin: -.7rem 0 0 0;

  ${({ isMobile }) => isMobile && css`
    width: 23%;
    margin: -.5rem 0 0 1rem;

    p {
      color: #FFFF;
    }
  `}
`;

export const SelectMenu = styled.div<SelectMenuProps>`
  width: 100%;
  position:relative;
  margin: .5rem 0 .5rem 0;

  svg {
    ${({ isOpen }) => isOpen && css`transform: rotate(180deg);`}
  }

  ul {
    ${({ isOpen }) => !isOpen && css`display: none;`}
  }
`;

export const SelectButton = styled.div`
  width: 100%;
  height: 33px;
  display: flex;

  cursor: pointer;

  font-size: 18px;

  border-radius: 8px;

  align-items: center;
  justify-content: space-between;
`;

export const ArrowIcon = styled(ArrowSvg)`
  width: 8px;
  margin: .4rem 0 0 .2rem;
  transform: rotate(360deg);
`;

export const Options = styled.ul`
  left: 0;
  top: 70%;

  right: 0;
  z-index: 1;

  width: 100%;
  height: 100px;

  overflow-y: scroll;
  overflow-x: hidden;

  padding: 0 10px;
  position:absolute;

  background: #fff;
  margin-top: 10px;
  transform: animation;
  border: 1px solid #B7B7B7;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);

  @media (max-width: 820px) {
    padding: 0;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #C0C0C0;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 30px;
  }
`;

export const Option = styled.li`
  width: 100%;
  height: 35px;

  display: flex;
  cursor: pointer;

  border-radius: 8px;
  align-items: center;

  @media (max-width: 414px) {
    margin: 1rem 0 1rem .5rem;
  }
`;

export const Item = styled.div`
  color: black;

  &:hover { font-weight: bold; }

  @media(max-width: 820px) {
    margin: 0 -0.5rem;
  }
`;

