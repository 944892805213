import styled from 'styled-components';

export const Container = styled.div`
  width: 45px;
  height: 45px;
  display: none;
  
  @media (max-width:820px) {
    display: flex;
    cursor: pointer;
    /* margin: 0 1rem 0 0; */
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    background: var(--green);
  }

  div {
    width: 30px;
    height: 4px;
    /* margin: .3rem; */
    border-radius: 5px;
    background: #FFFFFF;
  }
`;
