export const randomIdGen = (len: number) => {
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < len; i++) {
    let randomId = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomId);
  }

  return result;
}
