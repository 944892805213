import React from 'react';
import { useAuth } from '@/hooks';
import { useSnackbar } from 'notistack';
import { RegisterService } from '@/service';
import { cities } from '@/utils/municipies';
import { emailValidator } from '@/utils/emailValidator';
import { FormHandles, SubmitHandler } from '@unform/core';

interface IMunicipies {
  id: number;
  nome: string;
}

interface ISubmit {
  name: string;
  uf: string;
  municipio: string;
  cargo: string;
  instituicao: string;
  email: string;
  password: string;
  newsletter: boolean;
  atividade_principal: string;
  politica_privacidade: boolean;
  confirm?: string;
}

export function useRegisterService() {
  const { setOpenRegister } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = React.useState('');
  const { createUser } = new RegisterService();
  const formRef = React.useRef<FormHandles>(null);
  const [success, setSuccess] = React.useState('');
  const [municipies, setMunicipies] = React.useState<string[]>([]);
  const [federalUnion, setFederalUnion] = React.useState<string | null>(null);

  const handleSubmit: SubmitHandler<ISubmit> = data => {
    const copy = {...data};

    const { status, message } = emailValidator(copy.email);

    if (!status) {
      setError(message);
      formRef.current?.setFieldError('email', message);
      return;
    }

    const r = Object.entries(copy).some(item => item[1] === '0');
    if (r) {
      Object.entries(copy).forEach(([key, value]) => {
        if (value === '0') {
          formRef.current?.setFieldError(key, 'error');
        }
      });
      setError('Campo(s) obrigatório(s)');
      return;
    }

    if (copy.password !== copy.confirm) {
      
      setError('as senhas devem ser correspondentes.');
      formRef.current?.setFieldError('password', 'error');
      formRef.current?.setFieldError('confirm', 'error');
    }

    delete copy.confirm;

    createUser(copy).then(({ data }) => {
      if (data.status === 0) {
        setError(data.message);
        return;
      }
      setOpenRegister(false);
      setError('');
      enqueueSnackbar(data.message, { variant: 'success' });
    });
  };

  React.useEffect(() => {
    if (federalUnion) {
      const data = cities?.map(item =>
        item.sigla === federalUnion
        ? item.cidades
        : null
      ).filter(item => item !== null)[0];
      setMunicipies(data as string[]);
    }
  }, [federalUnion]);

  return  {
    error,
    success,
    formRef,
    municipies,
    handleSubmit,
    federalUnion,
    setFederalUnion,
  };
}
