export const activity = [
  { id: 1, label: 'Trabalho na agropecuária' },
  { id: 2, label: 'Agricultura' },
  { id: 3, label: 'Pecuária' },
  { id: 4, label: 'Produção Florestal' },
  { id: 5, label: 'Pesca' },
  { id: 6, label: 'Aquicultura' },
  { id: 7, label: 'Trabalho na indústria' },
  { id: 8, label: 'Indústrias extrativas' },
  { id: 9, label: 'Indústrias de transformação' },
  { id: 10, label: 'Eletricidade e gás, agua, esgoto, atividades de gestão de residuos' },
  { id: 11, label: 'Construção' },
  { id: 12, label: 'Trabalho em serviços' },
  { id: 13, label: 'Comércio' },
  { id: 14, label: 'Atividades financeiras, de seguros e serviços reacionados' },
  { id: 15, label: 'Atividades profissionais, científicas e técnicas' },
  { id: 16, label: 'Administração pública, defesa e seguridade social' },
  { id: 17, label: 'Educação' },
  { id: 18, label: 'Saúde e serviços sociais' },
  { id: 19, label: 'Artes, cultura, esportes e recreação' },
  { id: 20, label: 'Outras atividades de serviços' },
  { id: 21, label: 'Estudante' },
  { id: 22, label: 'Outro' }
];
