import styled from 'styled-components';

interface IProps {
  length?: number;
}

export const Container = styled.div<IProps>`
  width: ${props => props.length ? props.length: 70}px;
`;

export const Title = styled.p`
  margin: 0;
  color: #FFFFFF;
  cursor: pointer;
  text-align: center;
  letter-spacing: 0px;
  font: normal normal 300 16px/26px Poppins;

  &:hover {
    font: normal normal bold 16px/26px Poppins;
  }
`;
