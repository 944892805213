import React from 'react';

export const Activities: React.FC = () => {
  return (
    <>
      <optgroup label='Trabalho na agropecuária'>
        <option value='Agricultura'>Agricultura</option>
        <option value='Pecuária'>Pecuária</option>
        <option value='Produção Florestal'>Produção Florestal</option>
        <option value='Pesca'>Pesca</option>
        <option value='Aquicultura'>Aquicultura</option>
      </optgroup>
      <optgroup label='Trabalho na indústria'>
        <option value='Indústrias extrativas'>Indústrias extrativas</option>
        <option value='Indústrias de transformação'>Indústrias de transformação</option>
        <option
          value='Eletricidade e gás, agua, esgoto, atividades de gestão de residuos'
        >
          Eletricidade e gás, agua, esgoto, atividades de gestão de residuos
        </option>
        <option value='Construção'>Construção</option>
      </optgroup>
      <optgroup label='Trabalho em serviços'>
        <option value='Comércio'>Comércio</option>
        <option value='Atividades financeiras, de seguros e serviços reacionados'>Atividades financeiras, de seguros e serviços reacionados</option>
        <option value='Atividades profissionais, científicas e técnicas'>Atividades profissionais, científicas e técnicas</option>
        <option value='Administração pública, defesa e seguridade social'>Administração pública, defesa e seguridade social</option>
        <option value='Educação'>Educação</option>
        <option value='Saúde e serviços sociais'>Saúde e serviços sociais</option>
        <option value='Artes, cultura, esportes e recreação'>Artes, cultura, esportes e recreação</option>
        <option value='Outras atividades de serviços'>Outras atividades de serviços</option>
      </optgroup>
      <optgroup label='Estudante'>
        <option value='Estudante'>Estudante</option>
      </optgroup>
      <optgroup label='Outro'>
        <option value='Outro'>Outro</option>
      </optgroup>
    </>
  );
};
