import styled from 'styled-components';

export const Container = styled.nav`
  width: 600px;
  display: flex;
  justify-content: space-around;

  @media (max-width: 820px) {
    display: none;
  }
`;

export const List = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-around;

  /* li:nth-child(1) {
    margin: 0 0 0 3rem;
  } */
`;

interface IListProps {
  active?: boolean;
  wSpan: string;
}

export const ListItem = styled.li<IListProps>`
  width: auto;
  display: flex;
  margin: 0 .5rem 0 .5rem;
  flex-direction: column;

  p {
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    letter-spacing: 0px;
    transition: font-weight .1s ease-in-out;
    font: normal normal 300 18px/26px Poppins;
    font-weight: ${props => props.active ? 'bold' : `normal normal 300 16px/26px Poppins`};
  }

  span {
    top: 10%;
    height: 5px;
    display: flex;
    cursor: pointer;
    position: relative;
    width: ${props => props.wSpan};
    transition: background .1s ease-in-out;
    background: ${props => props.active ? '#20725E' : 'transparent'};
  }

  &:hover {
    span {
      opacity: .5;
      background: #20725E;
      transition: background .1s ease-in-out;
    }
  }
`;
