import React from 'react';
import { Container, Title } from './style';

interface IProps {
  title: string;
  length?: number;
  onClick?: () => void;
}

export const TextForm: React.FC<IProps> = ({ title, length, onClick }) => {
  return (
    <Container length={length}>
      <Title onClick={onClick}>{title}</Title>
    </Container>
  );
};
