import React from 'react';
import { useAuth } from '@/hooks';
import { CloseWrapper, Close,  } from '../styles';
import { LoginGuide } from '@/global/guides/Login';
import { IconInput } from '@/global/components/Inputs/IconTextField';
import { User, PadLock, Container, WrapperForm, WrapperLogin } from './styles';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DrawerLogin: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { handleLogin } = useAuth();

  return (
    <Container isOpen={isOpen}>
      <CloseWrapper onClick={onClose}>
        <Close />
      </CloseWrapper>
      <WrapperForm>
        <div className='span'>
          <span>Login</span>
        </div>
        <WrapperLogin>
          <LoginGuide onSubmit={handleLogin}>
            <IconInput name='email' placeholder='Email' icon={<User />} />
            <IconInput name='password' placeholder='Senha' icon={<PadLock />} />
          </LoginGuide>
        </WrapperLogin>
      </WrapperForm>
    </Container>
  );
};
