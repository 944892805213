import styled from 'styled-components';
import UserIcon from '../assets/user.svg';
import PadLockIcon from '../assets/pad_lock.svg';

interface WrapperLoginProps {
  isOpen: boolean;
}

export const Container = styled.div<WrapperLoginProps>`
  top: -40px;
  width: 380px;
  z-index: 9999;
  height: 460px;
  right: 119.3px;
  position: fixed;
  z-index: 9999999;
  background: var(--light-gray);
  box-sizing: border-box;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  border: 2px solid gray;
  justify-content: center;
  align-items: center;
  clip-path: polygon(9.75% 25%, 56.5% 25%, 67.29% 25%, 71.73% 22.24%, 76.17% 25%, 86% 25%, 86% 75%, 9.75% 75%);


  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}
  @media (max-width: 820px) {
    display: none;
  }

  @media (min-width: 821px) {
    right: 46px;
  }

  @media (min-width: 850px) {
    right: 46px;
  }

  @media (min-width: 900px) {
    right: 46px;
  }


  @media (min-width: 950px) {
    right: 64px;
  }

  @media (min-width: 1150px) {
    right: 105px;
  }

  @media (min-width: 1200px) {
    right: 113px;
  }


  @media (min-width: 1250px) {
    right: 123px;
  }

  @media (min-width: 1300px) {
    right: 128px;
  }

  @media (min-width: 1350px) {
    right: 133px;
  }


  @media (min-width: 1400px) {
    right: 142px;
  }

  @media (min-width: 1450px) {
    right: 152px;
  }

  @media (min-width: 1500px) {
    right: 159px;
  }

  @media (min-width: 1550px) {
    right: 167px;
  }


  @media (min-width: 1600px) {
    right: 172px;
  }

  @media (min-width: 1700px) {
    right: 172px;
  }

  @media (min-width: 1800px) {
    right: 189px;
  }

  @media (min-width: 1900px) {
    right: 217px;
  }

  @media (min-width: 2000px) {
    right: 232px;
  }

  @media (min-width: 2100px) {
    right: 255px;
  }

  @media (min-width: 2200px) {
    right: 273px;
  }

  @media (min-width: 2300px) {
    right: 285px;
  }

  @media (min-width: 2400px) {
    right: 302px;
  }

  @media (min-width: 2500px) {
    right: 320px;
  }

  @media (min-width: 2600px) {
    right: 338px;
  }

  @media (min-width: 2700px) {
    right: 355px;
  }

  @media (min-width: 2800px) {
    right: 370px;
  }

  @media (min-width: 2900px) {
    right: 388px;
  }

  @media (min-width: 3000px) {
    right: 404px;
  }

  @media (min-width: 3100px) {
    right: 413px;
  }
`;

export const ClipPathWrapper = styled.div<WrapperLoginProps>`
  width: 380px;
  height: 454px;
  background: #fff;
  z-index: 9999999;
  align-items: center;
  margin: 0 0 0 0.03rem;
  flex-direction: column;
  justify-content: center;
  display: ${props => props.isOpen ? 'flex': 'none'};
  clip-path: polygon(9.75% 25%, 56.5% 25%, 67.29% 25%, 71.73% 22.24%, 76.17% 25%, 86% 25%, 86% 75%, 9.75% 75%);
  @media (max-width: 820px) {
    display: none;
  }
`;


export const User = styled(UserIcon)`
  width: 10%;
  height: 15px;
  margin-right: .1rem;
  @media (max-width: 820px) {
    margin-right: 0;
  }
`;

export const PadLock = styled(PadLockIcon)`
  width: 10%;
  height: 15px;
  margin-right: .1rem;
  @media (max-width: 820px) {
    margin-right: 0;
  }
`;

export const Overlay = styled.div<WrapperLoginProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999999;
  background: transparent;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;
