import styled from 'styled-components';

interface IProps {
  open: boolean;
}

export const ContentWrapper = styled.div<IProps>`
  top: 95px;
  width: 422px;
  z-index: 9999999;
  position: absolute;
  height: fit-content;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: ${props => props.open ? 'block' : 'none'};

  @media (max-width: 600px) {
    width: 80%;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: grid;
  padding: .5rem;
  background: #123645;
  grid-template-columns: 3fr .2fr;
  grid-template-areas: 'title' 'close'
`;


export const Title = styled.p`
  color: #FFFFFF;
  grid-area: title;
  justify-self: center;
  letter-spacing: .1rem;
`;


export const Close = styled.div`
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: #FFFFFF;
  margin: .3rem 0 0 0;
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
`;

export const FormWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  height: fit-content;

  span {
    font: normal normal normal 14px/24px Poppins;
    letter-spacing: -0.36px;
    color: #000000;
  }

  button {
    width: 100%;
    display: flex;
    margin: 1rem 0 0 0;
    justify-content: center;
  }
`;

export const FieldWrapper = styled.div`
  margin: 1rem 0 0 0;
`;

export const SendMessageWrapper = styled.div`
  width: 422px;
  padding: 1rem;
  background: #FFFF;
  height: fit-content;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.61);
  -webkit-box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.61);


  p {
    font: normal normal normal 14px/24px Poppins;
    letter-spacing: -0.36px;
    color: #000000;
  }
`;

export const Overlay = styled.div<IProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  position: fixed;
  background: rgba(0,0,0,.7);
  display: ${props => props.open ? 'block' : 'none'};
`;
