import {
  Item,
  Option,
  Options,
  ArrowIcon,
  SelectMenu,
  SelectButton,
  InputContainer,
} from './styles';
import React from 'react';
import { useRouter } from 'next/router';
import { estados } from '@/utils/estados';
import { SinteseContext } from '@/context';
import { randomIdGen } from '@/utils/randomId';

interface IProps {
  label: string;
  isMobile?: boolean;
}

export const ChallengeItem: React.FC<IProps> = ({ label, isMobile=false }) => {
  const router = useRouter();
  const randomId = randomIdGen(5);
  const [exitDiv, setExitDiv] = React.useState(false);
  const { setState } = React.useContext(SinteseContext);
  const [exitDiv2, setExitDiv2] = React.useState(false);
  const [openInput, setOpenInput] = React.useState(false);

  React.useEffect(() => {
    if (exitDiv || exitDiv2) {
      setOpenInput(!openInput);
    }
  }, [exitDiv, exitDiv2]);

  const handleNavigate = (sigla: string) => {
    setState(sigla);
    router.push('/sintese');
  };

  const handleOpenMobile = () => {
    if (isMobile) {
      setOpenInput(!openInput);
    }
  };

  return (
    <InputContainer isMobile={isMobile}>
      <SelectMenu isOpen={openInput}>
        <SelectButton>
          <div>
            <p
              onClick={handleOpenMobile}
              onMouseOver={() => setOpenInput(true)}
              onMouseLeave={() => setExitDiv2(!exitDiv2)}
            >{label}</p>
          </div>
          <ArrowIcon />
        </SelectButton>
        <Options className='my_scrollbar' onMouseLeave={() => setExitDiv(!exitDiv)}>
          {estados.map(item => (
            <Option key={randomId}>
              <Item onClick={() => handleNavigate(item.sigla)}>{item.sigla}</Item>
            </Option>
          ))}
        </Options>
      </SelectMenu>
      </InputContainer>
  );
};
