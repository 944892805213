import styled from 'styled-components';

interface IProps {
  error: boolean;
}

export const InputWrapper = styled.div<IProps>`
  height: 35px;
  display: flex;
  background: #E7E7E7;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #C2C1C1BF;
  border: ${props => props.error && `2px solid #E52927`};

  input {
    width: 84%;
    height: 100%;
    border: none;
    padding: .5rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #000000;
    }
  }
`;
