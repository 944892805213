import styled from 'styled-components';
import UserIcon from '../../../../atoms/Header/assets/user.svg';
import PadLockIcon from '../../../../atoms/Header/assets/pad_lock.svg';

interface HamburgerProps {
  isOpen: boolean;
}


export const Container = styled.div<HamburgerProps>`
  width: 100%;
  height: 43%;
  z-index: 9999;
  display: none;
  background: var(--green-drawer);

  @media (max-width:820px) {
    height: 365px;
    top: 0;
    right: 0%;
    width: 100%;
    position: fixed;
    flex-direction: column;
    background: var(--green-drawer);
    display: ${props => props.isOpen ? 'flex' : 'none'};
  }
`;

export const WrapperForm = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .span {
    width: 80%;
    padding: 0 0 0.5rem 0;
  }

  span {
    color: #FFFFFF;
    font-style: italic;
  }
`;

export const WrapperLogin = styled.div`
  width: 80%;
  display: flex;
  background: #ffff;
  height: fit-content;
  justify-content: center;
`;

export const User = styled(UserIcon)`
  width: 10%;
  height: 15px;
  margin-right: .1rem;

  @media (max-width: 820px) {
    margin-right: 0;
  }


`;

export const PadLock = styled(PadLockIcon)`
  width: 10%;
  height: 15px;
  margin-right: .1rem;
  @media (max-width: 820px) {
    margin-right: 0;
  }
`;
