import styled from 'styled-components';

export const WrapperCheckbox = styled.div`
    display: flex;
    margin: .5rem 0 0 0;

    label {
      font: normal normal normal 12px/19px Poppins;
      text-decoration: none;
      margin-left: 5px;
    }
`;
