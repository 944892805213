import React from 'react';
import { useField } from '@unform/core';
import { WrapperCheckbox } from './styles';

type InputProps = JSX.IntrinsicElements['input'] & {
  name: string;
  label?: string | JSX.Element;
};

export function Checkbox({ name, value, label, ...rest }: InputProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const defaultChecked = defaultValue === value;

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => ref.current.checked,
      clearValue: ref => {
        ref.current.checked = defaultChecked;
      },
      setValue: (ref, value) => {
        ref.current.checked = value;
      },
    });
  }, [defaultValue, fieldName, registerField, defaultChecked]);

  return (
    <WrapperCheckbox>
      <input
        defaultChecked={defaultChecked}
        ref={inputRef}
        value={value}
        type="checkbox"
        id={fieldName}
        {...rest}
      />

      <label htmlFor={fieldName} key={fieldName}>
        {label}
      </label>
      {error && <span>{error}</span>}
    </WrapperCheckbox>
  );
}
