import styled from 'styled-components';

export const ContainerList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  li:nth-last-child(1), li:nth-last-child(2) {
    font-style: italic;
  };

  li {
    opacity: .8;
    color: #FFFFFF;
    font-size: 1.1rem;
    list-style-type: none;
    margin: 0 0 .5rem 1rem;

    p {
      cursor: pointer;
      &:hover {
        font-weight: bold;
      }
    }
  }

  button {
    width: 50%;
    opacity: .8;
    display: flex;
    color: #FFFFFF;
    background: none;
    font-size: 1.1rem;
    margin: 0 0 .5rem .5rem;
    justify-content: flex-start;

    &:hover {
      font-weight: bold;
    }
  }
`;


export const Divider = styled.div`
  width:80%;
  height: 2px;
  background: #FFFFFF80;
  margin: 1rem 0 1rem 1rem;
`;

