import {
  Button,
  ErrorIcon,
  FormWrapper,
  CreateWrapper,
  ErrorWrapper,
  WrapperActions,
} from './styles';
import React from 'react';
import { useAuth } from '@/hooks';
import { Form } from '@unform/web';
import { rememberMeStore } from '@/service/store/rememberme';
import { Checkbox } from '@/global/components/Inputs/Checkbox';

interface IProps {
  onSubmit: () => void;
  width?: string;
  children: React.ReactNode;
}

export const LoginGuide: React.FC<IProps> = ({ onSubmit, children, width }) => {
  const isWidth = width || '70';
  const {
    formRef,
    openLogin,
    errorMessage,
    openRegister,
    setOpenLogin,
    setOpenRegister,
    openForgotPassword,
    setOPenForgotPassword } = useAuth();
  const { email, rememberMe } = rememberMeStore();

  const handleForgotPassword = () => {
    setOpenLogin(!openLogin);
    setOPenForgotPassword(!openForgotPassword);
  };

  const handleRegister = () => {
    setOpenLogin(!openLogin);
    setOpenRegister(!openRegister);
  };

  return (
    <>
      <FormWrapper width={isWidth}>
        <Form ref={formRef} onSubmit={onSubmit} initialData={{
          email, rememberMe
        }}>
          {children}
          <WrapperActions>
            <Checkbox defaultChecked name='rememberMe' label='Lembrar-me'/>
            <p onClick={handleForgotPassword}>Esqueci a senha</p>
          </WrapperActions>
          <Button type='submit'>Login</Button>
          <CreateWrapper>
            <p>Não possui login?</p>
            <p onClick={handleRegister}>Cadastre-se</p>
          </CreateWrapper>
          {!!errorMessage === true && (
            <ErrorWrapper>
              <ErrorIcon />
              <p>{errorMessage}</p>
            </ErrorWrapper>
          )}
        </Form>
      </FormWrapper>
    </>
  );
};
