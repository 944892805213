import React from 'react';
import { InputWrapper } from './styles';
import { useField } from '@unform/core';

type InputProps = JSX.IntrinsicElements['input'] & {
  icon: any;
  name: string;
};

export function IconInput({ name, type='text', icon, placeholder,...rest }: InputProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [internalPlaceHolder, setInternalPlaceHolder] = React.useState(placeholder);

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <InputWrapper error={!!error}>
      {icon}
      <input
        type={type}
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        placeholder={internalPlaceHolder}
        onFocus={() => setInternalPlaceHolder('')}
        onBlur={() => {
          if (!inputRef.current?.value) {
            setInternalPlaceHolder(placeholder);
          }
        }}
        {...rest}
      />
    </InputWrapper>
  );
}
