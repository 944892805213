import {
  Title,
  Close,
  Header,
  Overlay,
  ErrorWrapper,
  WrapperInput,
  FormContainer,
  ContentWrapper,
  ExclamationMark,
} from './styles';
import React from 'react';
import { useAuth } from '@/hooks';
import { Form } from '@unform/web';
import { estados } from '@/utils/estados';
import { useMediaQuery } from '@mui/material';
import { useRegisterService } from './service';
import { activity } from '@/utils/mainActivity';
import { canvasSize } from '@/utils/canvasMatches';
import { Checkbox } from '@/global/components/Inputs/Checkbox';
import { TextField } from '@/global/components/Inputs/TextField';
import { SelectField } from '@/global/components/Inputs/SelectField';
import { Activities } from '@/global/components/Inputs/ActivitesOptions';

interface IProps {
  open: boolean;
  onClose: () => void;
}
 
export const RegisterForm: React.FC<IProps> = ({ open, onClose }) => {
  const matches = useMediaQuery(canvasSize);
  const { openRegister, setOpenRegister } = useAuth();
  const {
    error,
    formRef,
    municipies,
    handleSubmit,
    setFederalUnion,
  } = useRegisterService();

  return (
    <>
      <ContentWrapper open={open}>
        <Header>
          <Title>CADASTRO</Title>
          <Close onClick={onClose}/>
        </Header>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <WrapperInput>
              <TextField
                required
                name='name'
                width='480px'
                placeholder='Nome completo*'
                onChange={() => formRef.current?.setFieldError('name', '')}
              />
            </WrapperInput>
            {!matches && (
              <WrapperInput>
                <SelectField
                  name='uf'
                  width='240px'
                  required={true}
                  defaultValue='0'
                  defaultItem='UF*'
                  options={estados}
                  onChange={e => {
                    setFederalUnion(e.target.value);
                    formRef.current?.setFieldError('uf', '')
                  }}
                >
                {estados.map(option => (
                  <option key={option.id} value={option.sigla}>
                    {option.sigla}
                  </option>
                ))}
                </SelectField>
                <SelectField
                  width='225px'
                  name='municipio'
                  defaultValue='0'
                  defaultItem='Município*'
                  options={municipies as unknown as Record<string, unknown>[]}
                  onChange={() => formRef.current?.setFieldError('municipio', '')}
                >
                {municipies?.map((item: any, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
                </SelectField>
              </WrapperInput>
            )}
            {matches && (
              <>
                <WrapperInput>
                  <SelectField
                    name='uf'
                    width='100%'
                    defaultItem='UF'
                    defaultValue='0'
                    options={estados}
                    onChange={e => {
                      setFederalUnion(e.target.value);
                      formRef.current?.setFieldError('uf', '')
                    }}
                  >
                  {estados.map(option => (
                    <option key={option.id} value={option.sigla}>
                      {option.sigla}
                    </option>
                  ))}
                  </SelectField>
                </WrapperInput>
                <WrapperInput>
                  <SelectField
                    name='municipio'
                    defaultValue='0'
                    defaultItem='Município'
                    options={municipies as unknown as Record<string, unknown>[]}
                    onChange={() => formRef.current?.setFieldError('municipio', '')}

                  >
                  {municipies?.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                  </SelectField>
                </WrapperInput>
              </>
            )}
            <WrapperInput>
              <SelectField
                defaultValue='0'
                options={activity}
                name="atividade_principal"
                width={matches ? '100%' : '480px'}
                defaultItem='Atividade Principal*'
                onChange={() => formRef.current?.setFieldError('atividade_principal', '')}
              >
              <Activities />
              </SelectField>
            </WrapperInput>
            {!matches && (
              <WrapperInput>
                <TextField
                  width='240px'
                  name='instituicao'
                  placeholder='Instituição'
                  onChange={() => formRef.current?.setFieldError('instituicao', '')}
                />
                <TextField
                  width='225px'
                  name='cargo'
                  placeholder='Cargo'
                  onChange={() => formRef.current?.setFieldError('cargo', '')}
                />
              </WrapperInput>
            )}
            {matches && (
              <>
                <WrapperInput>
                  <TextField
                    name='instituicao'
                    placeholder='Instituição'
                    onChange={() => formRef.current?.setFieldError('instituicao', '')}
                  />
                </WrapperInput>
                <WrapperInput>
                  <TextField
                    name='cargo'
                    placeholder='Cargo'
                    onChange={() => formRef.current?.setFieldError('cargo', '')}
                  />
                </WrapperInput>
              </>
            )}
            <WrapperInput>
              <TextField
                required
                name='email'
                width='480px'
                placeholder='Email*'
                onChange={() => formRef.current?.setFieldError('email', '')}
              />
            </WrapperInput>
            {!matches && (
              <WrapperInput>
                <div className='caracteres'>
                  <TextField
                    width='240px'
                    required
                    name='password'
                    placeholder='Senha*'
                    onChange={() => formRef.current?.setFieldError('password', '')}
                  />
                  <p>&#40;Mínimo de 6 caracteres&#41;</p>
                </div>
                <TextField
                  width='225px'
                  required
                  name='confirm'
                  placeholder='Confirmar Senha*'
                  onChange={() => formRef.current?.setFieldError('confirm', '')}
                />
              </WrapperInput>
            )}
            {matches && (
              <>
                <WrapperInput>
                  <div className='caracteres'>
                    <TextField
                      required
                      name='password'
                      placeholder='Senha*'
                      onChange={() => formRef.current?.setFieldError('password', '')}
                    />
                    <p>&#40;Mínimo de 6 caracteres&#41;</p>
                  </div>
                </WrapperInput>
                <WrapperInput>
                  <TextField
                    required
                    name='confirm'
                    placeholder='Confirmar Senha*'
                    onChange={() => formRef.current?.setFieldError('confirm', '')}
                  />
                </WrapperInput>
              </>
            )}
            <div className='checkbox'>
              <Checkbox name='newsletter' label=' Aceito em receber novidade por e-mail' />
              <Checkbox required name='politica_privacidade' defaultChecked label={
                <p className="privacy_politic_style">
                  Concordo com a
                  <b>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href='/privacy/Politica_de_Privacidade.pdf'
                    >
                      Política de privacidade
                    </a>
                  </b>
                </p>
              } />
            </div>
            <div className='button'>
              <button>Cadastrar</button>
            </div>
          </Form>
          {!!error === true && (
            <ErrorWrapper>
              <ExclamationMark />
              <b>{error}</b>
            </ErrorWrapper>
          )}
        </FormContainer>
      </ContentWrapper>
      <Overlay open={open} onClick={() => setOpenRegister(!openRegister)} />
    </>
  );
};
