import React from 'react';
import { useNavigator } from '@/hooks';
import { Container, List, ListItem } from './styles';
import { ChallengeItem } from '@/global/components/challengeItem';

const t = ['62px','85px', '85px', '192px', '80px', '68px'];

export const Tabs: React.FC = () => {
  const {
    labels,
    toggleActive,
    toggleActiveStyles
  } = useNavigator();

  return (
    <Container>
      <List>
        {labels?.map(({ label }, index) => (
          <ListItem
            key={index}
            wSpan={t[index]}
            active={toggleActiveStyles(index)}
            onClick={() => toggleActive(index)}
          >
            {label !== 'Desafios' ? (
              <>
                <p>{label}</p>
                <span> </span>
              </>
            ) : (
              <ChallengeItem label={label} />
            )}
          </ListItem>
        ))}
      </List>
    </Container>
  );
};
