import styled from 'styled-components';

interface IProps {
  error: boolean;
}

export const WrapperInput = styled.div<IProps>`
  display: flex;
  flex-grow: 1;
  justify-content: center;

  input {
    border: none;
    height: 2rem;
    padding: .5rem;
    border-radius: 2px;
    border: 1px solid #C2C1C1BF;
    border: ${props => props.error && `2px solid #E52927`};

    &::placeholder {
      color: #000000;
    }
  }

  &::placeholder {
    text-align: left;
  }
`;
